import React, {Component} from 'react';
import Layout from '../components/layout';
import CatoImg from '../components/cato-image';
import {graphql} from 'gatsby';
import {mapStickyBanner, mapGartner} from '../common/utils';
import '../assets/styles/pages/_company.scss';
import '../assets/styles/responsive/pages/_company-mobile.scss';

const TeamMember = ({name, role, desc, image}) => {
    return (
        <div className={'team-member'}>
            {image && <CatoImg img={image} alt={`${name}'s picture`}/>}
            <div className={'name-role'}>
                {name && <h2>{name}</h2>}
                {role && <h3>{role}</h3>}
            </div>
            {desc && <span dangerouslySetInnerHTML={{__html: desc}}/>}
        </div>
    )
};

const Director = ({name, role, desc, image}) => {
    return (
        <div className={'director'}>
            {image && <CatoImg img={image} alt={`${name}'s picture`}/>}
            <div className={'name-role'}>
                {name && <h2>{name}</h2>}
                {role && <h3>{role}</h3>}
            </div>
            {desc && <span dangerouslySetInnerHTML={{__html: desc}}/>}
        </div>
    )
};

const Investor = ({image, desc}) => {
    return (
        <div className={'investor'}>
            {image && <CatoImg img={image} style={{marginBottom: '30px'}} alt={`investor picture`}/>}
            {desc && <div className={'desc-wrapper'} dangerouslySetInnerHTML={{__html: desc}}/>}
        </div>
    )
};


class CompanyPage extends Component {

    generateManagers = (acf) => {
        const {smallImageBoldSectionRows} = acf;
        return smallImageBoldSectionRows.map((item, i) => ({
            name: item.boldTitleText || "",
            role: item.boldSubtitleText || "",
            desc: item.text || "",
            image: item.image ? item.image : null,
        }))
    };

    generateDirectors = (acf) => {
        const {smallImageNonSectionRows} = acf;

        return smallImageNonSectionRows.map((item, i) => ({
            name: item.boldTitle || "",
            role: item.subtitle || "",
            desc: item.text || "",
            image: item.image ? item.image : null
        }))
    };

    generateInvestors = (acf) => {
        const {smallImageNoTitleSectionRows} = acf;

        return smallImageNoTitleSectionRows.map((item) => ({
            desc: item.text || "",
            image: item.image ? item.image : null
        }))
    };

    render() {

        const {data} = this.props;

        // const {wpPage} = data;
        const {wpPage} = data;
        const {headingSideAligned, smallImageTextSection, smallImageBoldTitleSubtitleSection, smallImageBoldTitleSection, resourcesBannerRows} = wpPage;

        const managers = smallImageBoldTitleSubtitleSection.smallImageBoldSectionRows ? this.generateManagers(smallImageBoldTitleSubtitleSection) : null;
        const directors = smallImageBoldTitleSection.smallImageNonSectionRows ? this.generateDirectors(smallImageBoldTitleSection) : null;
        const investors = smallImageTextSection.smallImageNoTitleSectionRows ? this.generateInvestors(smallImageTextSection) : null;

        const stickyBanner = mapStickyBanner(wpPage);

        const gartner = mapGartner(wpPage);

        return (
            <Layout
                className="company-page"
                whiteMenu={true}
                lang={this.props.pageContext.lang}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                gartner={gartner}
                seoPath={this.props.location.pathname}
                breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                translations={this.props.pageContext.translations}
                wpid={wpPage.databaseId}
                {...{stickyBanner}}>
                    <section className={'section-1'}>
                        <div className={'top-section'}>
                            {headingSideAligned.textHeadingGreenHeadline && <h1 className={'green-title'}>
                                {headingSideAligned.textHeadingGreenHeadline}
                            </h1>}
                            {headingSideAligned.textHeadingText &&
                            <div className="top-text">
                                {headingSideAligned.textHeadingText}
                            </div>
                            }
                        </div>
                    </section>
                    <section className={'section-management'} id={"section-management"}>
                        {smallImageBoldTitleSubtitleSection.smallImageBoldSectionHeadline && <h1>{smallImageBoldTitleSubtitleSection.smallImageBoldSectionHeadline}</h1>}
                        {managers && <div className={'restrict-1200'}>
                            {managers.map((v, k) => <TeamMember key={k} {...v}/>)}
                        </div>}
                    </section>
                    {resourcesBannerRows.resourcesList && resourcesBannerRows.resourcesList.length &&
                    <section className={'ebook'}>
                        <div className={'eBook-wrapper'}>
                            <div>
                                {resourcesBannerRows.resourcesList[0].resourceFields &&
                                resourcesBannerRows.resourcesList[0].resourceFields.resourceSmallImage &&
                                <CatoImg img={resourcesBannerRows.resourcesList[0].resourceFields.resourceSmallImage}/>}
                                {/*<img src={eBook} style={{maxWidth: '310px'}}/>*/}
                            </div>
                            <div className={'right'}>
                                {resourcesBannerRows.resourcesList[0].title && <h3>{resourcesBannerRows.resourcesList[0].title}</h3>}
                                <a href={resourcesBannerRows.resourcesList[0].resourceFields.resourceLink || "/"}>{resourcesBannerRows.resourcesList[0].resourceFields.resourceButtonText || "Resource"}</a>
                            </div>
                        </div>
                    </section>
                    }
                    <section className={'section-directors'} id={"section-directors"}>
                        {smallImageBoldTitleSection.smallImageNonSectionHeadline && <h1>{smallImageBoldTitleSection.smallImageNonSectionHeadline}</h1>}
                        {directors && <div className={'restrict-1200'}>
                            {directors.map((v, k) => <Director key={k} {...v}/>)}
                        </div>}
                    </section>
                    <div className="separator"/>
                    <section className={'section-investors'} id={"section-investors"}>
                        {smallImageTextSection.smallImageNoTitleSectionHeadline && <h1>{smallImageTextSection.smallImageNoTitleSectionHeadline}</h1>}
                        {investors && <div className={'restrict-1200'}>
                            {investors.map((v, k) => <Investor key={k} {...v}/>)}
                        </div>}
                    </section>
            </Layout>
        )
    }
}

export default CompanyPage;

export const pageQuery = graphql`
    query companyPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
            path: uri
            databaseId
            ...YoastData
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headingSideAligned {
                textHeadingGreenHeadline
                textHeadingText
            }
            smallImageTextSection {
                smallImageNoTitleSectionHeadline
                smallImageNoTitleSectionRows {
                    image {
                        altText
                        localFile {
                            childImageSharp {
                                fixed(height: 49) {
                                    ...GatsbyImageSharpFixed_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    text
                }
            }
            smallImageBoldTitleSection {
                smallImageNonSectionHeadline
                smallImageNonSectionRows {
                    image {
                        altText
                        localFile {
                            extension
                            publicURL
                        }
                    }
                    boldTitle
                    subtitle
                    text
                }
            }
            smallImageBoldTitleSubtitleSection {
                smallImageBoldSectionRows {
                    boldTitleText
                    boldSubtitleText
                    text
                    image {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 200){
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                }
            }
            ...StickyBanner
            ...GartnerStrip
            ...ResourcesBannerRows
        }
    }`;